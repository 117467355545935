(function () {
    var stopCallback = Mousetrap.prototype.stopCallback;

    Mousetrap.prototype.stopCallback = function(e, element) {
        // if escape has been pressed then no need to stop
        if(e.keyCode === 27) {
            return false;
        }

        return stopCallback(e, element);
    };

    var $filter = $('.filter-container form');

    // Filter
    Mousetrap.bind('f', function () {
        $filter.submit();
    });

    Mousetrap.bind('F', function () {
        var href = $filter.find('.filter-buttons a:contains(Zurücksetzen):eq(0)').attr('href');
        if (href) {
            window.location.href = href;
        }
    });

    // General
    Mousetrap.bind('esc', function () {
        document.activeElement.blur();
    }, 'keyup');
})();
