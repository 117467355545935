(function () {
    var toggleBrandSpinner = function () {
        $('#navbar-brand').toggle(400);
        $('#spinner').toggle(400);
    };

    // global ajax start handler
    $(document).ajaxStart(toggleBrandSpinner);

    // global ajax complete handler
    $(document).ajaxStop(toggleBrandSpinner);

    $(document).ajaxComplete(function (event, xhr) {
        if (!(xhr.responseJSON && xhr.responseJSON.message)) {
            return;
        }

        var statusCode = xhr.status;
        var level = 'info';

        switch (true) {
            case (statusCode >= 200 && statusCode < 300):
                level = 'success';
                break;
            case (statusCode >= 400 && statusCode < 500):
                level = 'warning';
                break;
            case (statusCode >= 500 && statusCode < 600):
                level = 'error';
                break;
        }

        $.pubsub('publish', 'flash.message', {
            level: level,
            message: xhr.responseJSON.message,
            errors: xhr.responseJSON.errors
        });
    });

    // global ajax error handler
    // $(document).ajaxError(function (event, response) {
    //     if (!response.responseJSON) {
    //         response.responseJSON = {message: 'Internal server error'};
    //     }
    //
    //     $.pubsub('publish', 'flash.message', {
    //         level: 'error',
    //         message: response.responseJSON.message,
    //         errors: response.responseJSON.errors
    //     });
    // });
})();
