'use strict';

(function () {
    $('input[data-prefix]').focus(function () {
        var $elmnt = $(this);

        $elmnt.val() == '' && $elmnt.val($elmnt.data('prefix'));
    });

    $('input[data-prefix]').blur(function (e) {
        var $elmnt = $(this);

        $elmnt.val() === $elmnt.data('prefix') && $elmnt.val('');
    });
})();
