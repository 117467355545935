'use strict';

(function () {
    $.pubsub('subscribe', 'flash.message', function (topic, data) {
        window.toastr[data.level](data.message);
    });

    $.pubsub('subscribe', 'flash.overlay.message', function (topic, data) {
        if ($('#flash-overlay-modal').length === 0) {
            $('body').append(Handlebars.templates.flash_overlay({
                title: data.title,
                body: data.body,
                modal_class: data.modal_class
            }));
        } else {
            $('#flash-overlay-modal .modal-title').text(data.title);
            $('#flash-overlay-modal .modal-body').html(data.body);
        }

        $('#flash-overlay-modal').modal();
    })
})();

