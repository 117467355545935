(function () {
    $.rails.allowAction = function (link) {
        if (link.data("confirm") == undefined) {
            return true;
        }

        $.rails.showConfirmationDialog(link);
        return false;
    };

    $.rails.confirmed = function (link) {
        link.data("confirm", null);
        link.trigger("click.rails");
    };

    $.rails.showConfirmationDialog = function (link) {
        var message = link.data("confirm");
        var title = link.data("confirm-title");
        var confirmBtn = link.data('confirm-btn-txt');
        var cancelBtn = link.data('confirm-cancel-btn-txt');

        swal({
            title: title,
            text: message,
            type: 'question',
            showCancelButton: true,
            confirmButtonText: (confirmBtn ? confirmBtn : 'Ok'),
            cancelButtonText: (cancelBtn ? cancelBtn : 'Abbrechen')
        }).then(function () {
            $.rails.confirmed(link);
        });
    };
})();
